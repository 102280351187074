import React, { FC } from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-splash-screen': { application: string };
        }
    }
}

export const SplashScreen: FC = () => {
    return (
        <sgwt-splash-screen
            application="service-board-backoffice"
        />
    );
};
