import { setupSGWTConnect, SGWTConnectCore } from '@sgwt/connect-core';
import { configuration } from 'config/constants';
import { SgConnectEnvironment } from './SgConnectEnvironment';
import { Utilities } from 'services/ApiService/ServiceBoard/ServiceBoardApiClient';

export type OriginNetworkType = 'intranet' | 'internet';

type OriginNetworkResponse = {
    'origin-network': OriginNetworkType,
}

declare global {
    interface Window {
        sgwtConnect?: SGWTConnectCore;
        SGWTWidgetConfiguration: { environment: SgConnectEnvironment };
    }
}

export interface SgConnectSettings {
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    environment: SgConnectEnvironment;
}

export let sgConnect: SGWTConnectCore | undefined;

export const getAuthorizationHeader = (): string => loadSGConnect()?.getAuthorizationHeader?.() ?? '';

export const initSGConnect = (): void => {
    const settings = getSGConnectSettings();

    const sgConnect = loadSGConnect(settings);

    setupPassiveMode(sgConnect);

    if (!sgConnect.isAuthorized()) {
        sgConnect.requestAuthorization();
        return;
    }
};

export const initSGWTWidgetConfiguration = () => {
    const settings = getSGConnectSettings();
    if (window.SGWTWidgetConfiguration) {
        console.error('SGWTWidgetConfiguration is already configured and environment can\'t be updated because it was already used');
    } else {
        window.SGWTWidgetConfiguration = {
            environment: settings.environment,
        };
    }
};

export const loadSGConnect = (settings: SgConnectSettings = getSGConnectSettings()) => {
    if (!settings.authorizationEndpoint) {
        throw new Error('[loadSGConnect] Missing authorizationEndpoint');
    }
    if (!settings.clientId) {
        throw new Error('[loadSGConnect] Missing clientId');
    }

    const isWindowDefined = typeof window !== 'undefined';
    if (!isWindowDefined || !window.sgwtConnect) {
        sgConnect = setupSGWTConnect({
            authorization_endpoint: settings?.authorizationEndpoint,
            client_id: settings?.clientId,
            scope: settings?.scope,
            acr_values: 'L1',
        });
        if (isWindowDefined) {
            window.sgwtConnect = sgConnect;
        }
    } else {
        sgConnect = window.sgwtConnect;
    }

    return sgConnect;
};

export const setupPassiveMode = (sgwtConnect: SGWTConnectCore) => {
    const widget = document.querySelector('sgwt-connect') as any;
    if (!widget || typeof widget.setSgwtConnectInstance === 'undefined') {
        setTimeout(() => setupPassiveMode(sgwtConnect), 100);
        return;
    }

    widget.setSgwtConnectInstance(sgwtConnect);
};

const getSGConnectSettings = () => {
    return {
        authorizationEndpoint: configuration.authorizationEndpoint,
        clientId: configuration.clientId,
        scope: configuration.scope,
        environment: configuration.sgConnectEnvironment,
    };
};

export const fetchIsFromIntranetNetwork = async (): Promise<OriginNetworkType> => {
    try {
        const url = `${configuration.baseApiUrls.sgConnect}/network/api/v1/origin`;
        const response = await fetch(url);
        const origin = await Utilities.processResponse<OriginNetworkResponse>(response, false, []);
        return origin['origin-network'];
    } catch {
        return 'intranet'; // default, intranet
    }
};
