import { generatePath } from 'react-router-dom';

export const RoutePaths = {
    Errors: {
        NotFound: {
            route: '*',
            url: () => '/error/not-found',
        },
    },
    Home: {
        route: '/',
        url: () => RoutePaths.Home.route,
        title: 'My Services Backoffice - Home',
    },
    DisclaimerMapping: {
        title: 'My Services Backoffice - Disclaimer questions mapping',
        List: {
            route: '/disclaimer-mapping',
            url: () => RoutePaths.DisclaimerMapping.List.route,
        },
        Edit: {
            route: '/disclaimer-mapping/:questionId',
            url: (questionId: number) => generatePath(RoutePaths.DisclaimerMapping.Edit.route, { questionId: `${questionId}` }),
        },
    },
} as const;

export type RouteParams = {
    Home: ExtractRouteParams<typeof RoutePaths.Home.route, string>,
    Errors: {
        NotFound: ExtractRouteParams<typeof RoutePaths.Errors.NotFound.route, string>,
    },
    DisclaimerMapping: {
        List: ExtractRouteParams<typeof RoutePaths.DisclaimerMapping.List.route, string>,
        Edit: ExtractRouteParams<typeof RoutePaths.DisclaimerMapping.Edit.route, number>,
    },
};

type ExtractRouteOptionalParam<T extends string, U = string | number | boolean> = T extends `${infer Param}?`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}*`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}+`
    ? { [_ in Param]: U }
    : { [_ in T]: U };

/* eslint-disable */
type ExtractRouteParams<T extends string, U = string | number | boolean> = string extends T
    ? { [_ in string]?: U }
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}/${infer Rest}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U> & ExtractRouteParams<Rest, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U> & ExtractRouteParams<Rest, U>
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U>
    : unknown;
